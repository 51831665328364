import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import Masonry from './Masonry';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing mb-5">
      <Container fluid className="ticket-header-image" />

      {/* <Container fluid className="header-image" /> */}
      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
        </Helmet>

        <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type="list" showCity showLocation />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
